@layer components {
  /*ANCHOR: Containers*/
  .container-light {
    @apply bg-neutral-light p-2;
  }

  .container-light-shadowed {
    @apply container-light shadow-lg;
  }

  .container-dark-border {
    @apply border-2 border-neutral-mid-200 p-4 rounded bg-neutral-mid-100;
  }

  .container-divider-border {
    @apply border-b border-neutral-mid-100;
  }

  .container-divider-border-top {
    @apply border-t border-neutral-mid-200;
  }

  .container-light-card {
    @apply container-light-shadowed rounded-md p-2 m-0.25;
  }

  .container-page-header {
    @apply text-title pb-4;
  }

  .container-page-padding {
    @apply px-4.5 pb-2 pt-4.5 tablet:p-2;
  }

  .container-resize {
    @apply resize-x overflow-auto mx-auto p-2;
  }

  .section-border {
    @apply flex my-2 justify-center border border-neutral-mid-50;
  }

  /*ANCHOR: Typography*/
  .text-title {
    @apply text-2xl font-bold text-neutral-mid-700;
  }

  .text-subheader {
    @apply text-xl font-semibold text-neutral-mid-600;
  }

  .text-base-semibold-dark {
    @apply text-base font-semibold text-neutral-mid-750 phone:text-sm;
  }

  .text-base-semibold {
    @apply text-base font-semibold text-neutral-mid-550 phone:text-sm;
  }

  .text-base-medium {
    @apply text-base font-medium text-neutral-mid-550 phone:text-sm;
  }

  .text-base-subtle {
    @apply text-neutral-mid-450 text-base phone:text-sm;
  }

  .text-subtle {
    @apply text-sm text-neutral-mid-450;
  }

  .text-accent {
    @apply text-society;
  }

  /*TODO: These names kinda suck. They don't represent what they are very well.*/
  .text-detailblock-title {
    @apply text-neutral-mid-600 text-base font-semibold;
  }

  .text-detailblock-dark-medium {
    @apply text-neutral-mid-650 text-sm font-medium;
  }

  .text-detailblock-base-semibold {
    @apply text-neutral-mid-600 text-sm font-semibold;
  }

  .text-detailblock-base-medium {
    @apply text-neutral-mid-600 text-sm font-medium;
  }

  .text-detailblock-base {
    @apply text-neutral-mid-600 text-sm;
  }

  .text-detailblock-subtle-semibold {
    @apply text-neutral-mid-450 text-sm font-semibold;
  }

  .text-detailblock-subtle-medium {
    @apply text-neutral-mid-450 text-sm font-medium;
  }

  .text-detailblock-subtle {
    @apply text-neutral-mid-450 text-sm;
  }

  .text-detailblock-subtle-xs {
    @apply text-neutral-mid-450 text-xs;
  }

  .text-detailblock-subdued {
    @apply text-neutral-mid-300 text-sm font-normal;
  }

  .text-accent-decorated {
    @apply text-accent underline font-extrabold;
  }

  .text-danger-small {
    @apply text-sm text-status-danger;
  }

  /*ANCHOR: Interactable*/
  .link-undecorated {
    @apply text-neutral-mid no-underline;
  }

  .link-decorated {
    @apply underline bg-transparent decoration-society-550 text-society-550 hover:text-society-500 hover:decoration-society-500 hover:cursor-pointer;
  }

  /*ANCHOR: Inputs*/
  .input-light {
    @apply p-0.75 rounded text-sm bg-neutral-light text-neutral-mid-700 ring-0 border-neutral-mid-200 placeholder:text-neutral-mid-200 focus:border-neutral-mid-300 focus:ring-0 focus:ring-transparent disabled:bg-neutral-light-550 disabled:text-neutral-light-900;
  }

  .input-mid {
    @apply p-0.75 rounded text-sm bg-neutral-light-tint text-neutral-mid-700 ring-0 border-neutral-mid-200 placeholder:text-neutral-mid-200 focus:ring-0 focus:ring-transparent disabled:bg-neutral-light-tint-550 disabled:text-neutral-light-900;
  }

  /*ANCHOR: Buttons*/
  .button-regular {
    @apply rounded-md border-2 border-solid px-1.5 py-1 text-sm font-medium disabled:opacity-60 disabled:hover:opacity-60;
  }

  .button-small {
    @apply rounded-md border-2 border-solid px-0.75 py-0.5 text-xs font-medium disabled:opacity-60 disabled:hover:opacity-60;
  }

  .button-regular-general-filled {
    @apply button-regular border-0 bg-society-550 text-neutral-light stroke-neutral-light hover:bg-society-500 disabled:hover:bg-society-550;
  }

  .button-regular-general-unfilled {
    @apply button-regular border-0 bg-transparent text-society-550 stroke-society-550 hover:text-society-500 hover:stroke-society-400 hover:bg-transparent disabled:hover:bg-transparent;
  }

  .button-regular-general-unfilled-text-accent {
    @apply text-accent-decorated group-hover:text-society-500 group-hover:stroke-society-500 bg-transparent hover:bg-transparent disabled:hover:bg-transparent;
  }

  .button-regular-neutral-filled {
    @apply button-regular border-0 bg-neutral-light-700 stroke-neutral-light-700 text-neutral-mid-750 hover:bg-neutral-light-800 disabled:hover:bg-neutral-light-700;
  }

  .button-regular-light-filled {
    @apply button-regular shadow-sm border border-society-100 bg-neutral-light stroke-society-550 text-society-550 hover:bg-neutral-light-200 disabled:hover:bg-neutral-light;
  }

  .button-regular-neutral-unfilled {
    @apply button-regular border-0 bg-transparent stroke-neutral-mid-500 text-neutral-mid-500 font-semibold hover:bg-transparent disabled:hover:bg-transparent;
  }

  .button-regular-neutral-unfilled-border {
    @apply button-regular bg-transparent hover:bg-neutral-light-550 stroke-neutral-mid-500 text-neutral-mid-500 font-semibold gap-1 border border-neutral-mid-200 shadow px-2 disabled:hover:bg-transparent;
  }

  .button-regular-destructive-filled {
    @apply button-regular border-0 bg-status-danger text-neutral-light stroke-neutral-light hover:bg-status-danger-tint disabled:hover:bg-status-danger;
  }

  .button-regular-destructive-unfilled {
    @apply button-regular border-0 text-status-danger stroke-status-danger bg-transparent hover:text-status-danger-tint hover:stroke-status-danger-tint hover:bg-transparent disabled:hover:bg-transparent;
  }

  .button-regular-add-filled {
    @apply button-regular border-0 bg-society-550 text-neutral-light stroke-neutral-light hover:bg-society-500 disabled:hover:bg-society-550;
  }

  .button-regular-add-unfilled {
    @apply button-regular border-0 bg-transparent text-society-550 hover:text-society-500 hover:bg-transparent disabled:hover:bg-transparent;
  }

  .button-small-general-filled {
    @apply button-small border-0 bg-society-550 text-neutral-light stroke-neutral-light hover:bg-society-500 disabled:hover:bg-society-550;
  }

  .button-small-neutral-filled {
    @apply button-small border-0 bg-neutral-light-700 text-neutral-mid-750 stroke-neutral-mid-750 hover:bg-neutral-light-800 disabled:hover:bg-neutral-light-700;
  }

  .button-small-destructive-filled {
    @apply button-small border-0 bg-status-danger text-neutral-light stroke-neutral-light hover:bg-status-danger-tint disabled:hover:bg-status-danger;
  }

  .button-small-destructive-unfilled {
    @apply button-small border-0 bg-transparent text-status-danger stroke-status-danger hover:text-status-danger-tint hover:bg-transparent disabled:hover:bg-transparent;
  }

  .button-small-add-unfilled {
    @apply button-small border-0 bg-transparent text-society-550 stroke-society-550 hover:text-society-500 hover:bg-transparent disabled:hover:bg-transparent;
  }

  .button-small-add-filled {
    @apply button-small border-0 bg-society-550 text-neutral-light stroke-neutral-light hover:bg-society-500 disabled:hover:bg-society-550;
  }

  .button-small-add-unfilled-flagged {
    @apply button-small underline border-0 bg-transparent text-status-flagged stroke-status-flagged hover:text-status-flagged-shade hover:bg-transparent disabled:hover:bg-transparent;
  }

  .button-disabled {
    @apply pointer-events-none opacity-50;
  }

  .button-small-success-filled {
    @apply button-small border-0 bg-status-success text-neutral-light stroke-neutral-light hover:bg-status-success-tint disabled:hover:bg-status-success;
  }

  .button-small-warning-filled {
    @apply button-small border-0 bg-status-warning text-neutral-mid-600 stroke-neutral-mid-600 hover:bg-status-warning-tint disabled:hover:bg-status-warning;
  }

  .button-text-show-more {
    @apply underline border-0 px-0 py-0 bg-transparent ml-1 text-society-550 font-semibold hover:bg-transparent hover:text-society-500;
  }

  /*ANCHOR: Icons/Images*/
  .icon-favorited {
    @apply favevent-icon stroke-status-flagged;
  }

  .icon-unfavorited {
    @apply favevent-icon stroke-neutral-mid fill-transparent;
  }

  .svg-interaction-positive {
    @apply hover:cursor-pointer;
  }

  .divider-circle {
    @apply rounded-full bg-neutral-mid-350;
  }

  /*ANCHOR: Datepicker*/
  .datepicker-shadow {
    @apply opacity-25 block appmodal-body-background bg-neutral-light-550 fixed inset-0 z-10;
  }
  .datepicker-flex {
    @apply flex flex-1 items-center;
  }
  .datepicker-chevron-icon {
    @apply w-2 h-2;
  }
  .datepicker-calendar-day-select-base {
    @apply bg-society text-neutral-light font-medium;
  }
  .chevron-icon {
    @apply w-6 h-6;
  }
  .footer-button-prefix {
    @apply w-full mr-3 transition-all duration-300 text-neutral-light font-medium border px-4 py-2 text-sm rounded-md focus:ring-2 focus:ring-offset-2 /*hover:bg-gray-50*/;
  }
}
